import Link from "next/link";
import Logo from "./logo";

export default function LandingPageFooter() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-4 order-1 lg:order-none">
            <div className="h-full flex flex-col sm:flex-row lg:flex-col justify-between">
              <Logo />
              {/* <div className="text-sm text-text-landing">
                    © Lavareach.com{" "}
                    <span className="text-text-landing ">-</span> All rights
                    reserved.
                  </div> */}

              <iframe
                src="https://status.lavareach.com/badge?theme=light"
                width="250"
                height="30"
                frameBorder="0"
              />
            </div>
          </div>

          {/* <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-text-landing font-medium mb-2">
              Usecases
            </h6>
            <ul className="text-sm space-y-2">
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/prospects-usecase"
                >
                  Prospect Database
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/realtime-buying-usecase"
                >
                  Trigger Based Signals
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/realtime-intent"
                >
                  Realtime Buying Intent
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/contact-data"
                >
                  Contact Data
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/linkedin-data"
                >
                  Linkedin Data
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/company-insights"
                >
                  Company Insights Data
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/techstack-financials"
                >
                  Technology Stack & Financials
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/ai-custom-research"
                >
                  AI Custom Research
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/ai-personalize"
                >
                  AI Personalization
                </Link>
              </li>
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/email-sequencer-usecase"
                >
                  Email Sequencer
                </Link>
              </li>
            </ul>
          </div> */}
          {/* <div className="sm:col-span-6 md:col-span-3 lg:col-span-2"></div> */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-text-landing font-medium mb-2">
              Sales Guides
            </h6>
            <ul className="text-sm space-y-2">
              <li>
                <Link
                  className="text-text-landing hover:underline"
                  href="/sales-terms"
                >
                  Sales Terms
                </Link>
              </li>
              <li>
                <Link
                  className=" text-text-landing hover:underline"
                  href="/blog"
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-text-landing font-medium mb-2">
              Legals
            </h6>
            <ul className="text-sm space-y-2">
              <li>
                <a
                  className="text-text-landing hover:underline transition duration-150 ease-in-out"
                  href="/terms-and-services"
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  className="text-text-landing hover:underline transition duration-150 ease-in-out"
                  href="/privacy-policy"
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="text-sm text-text-landing font-medium mb-2">
              Contact Us
            </h6>
            <ul className="text-sm space-y-2">
              <li>
                <a
                  className="text-text-landing hover:underline transition duration-150 ease-in-out"
                  href="mailto:info@lavareach.com"
                >
                  info@lavareach.com
                </a>
              </li>
              {/* <li>
                <a
                  className="text-text-landing hover:underline transition duration-150 ease-in-out"
                  href="#0"
                >
                  647-770-3660
                </a>
              </li> */}
              <li>
                <a
                  className="text-text-landing hover:underline transition duration-150 ease-in-out"
                  href="https://discord.gg/Y8su3YCm3n"
                  target="_blank"
                >
                  Join our Discord
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
